import React, { useState, useEffect } from "react";
import TenantService from "../../../services/tenant";
import TenantListView from "./tenant-list.view";

export default function TenantListContainer(props) {
  const [state, setState] = useState({
    tenants: [],
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const fetchTenants = await TenantService.getAll();
    setState({
      ...state,
      tenants: fetchTenants,
    });
  };

  const createTenant = () => {
    props.history.push("/tenant-detail");
  };

  const editTenant = (tenant) => {
    props.history.push({
      pathname: "/tenant-detail",
      search: "id=" + tenant.id,
      state: { tenant: tenant },
    });
  };

  const deleteTenant = async (tenant) => {
    try {
      await TenantService.delete(tenant.id);
      alert("Tenant changed successfully!");
    } catch (err) {
      alert(err);
    }
  };

  return (
    <TenantListView
      state={state}
      createTenant={createTenant}
      editTenant={editTenant}
      deleteTenant={deleteTenant}
    />
  );
}
