import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TenantListView({ state, createTenant, editTenant, deleteTenant }) {
  const classes = useStyles();
  let { tenants } = state;
  return (
    <React.Fragment>
      <h3>Tenants</h3>
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={createTenant}
          style={{ marginBottom: "5px" }}
        >
          <AddCircleOutlineIcon /> Add tenant
        </Button>
      </div>
      <Grid container>
        <Grid xs={12} item>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Tenant name</TableCell>
                  <TableCell align="center">Tenant code</TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell align="center">Created date</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tenants.map((tenant, index) => (
                  <TableRow className="active-row" key={index}>
                    <TableCell component="th" scope="row" align="center">
                      {tenant.name}
                    </TableCell>
                    <TableCell align="center">{tenant.code}</TableCell>
                    <TableCell align="center">{tenant.description}</TableCell>
                    <TableCell align="center">{tenant.createdDate}</TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        onClick={() => editTenant(tenant)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => deleteTenant(tenant)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
