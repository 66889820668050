import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DeviceService from "../../services/device";
import "./device.css";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import ToysIcon from "@material-ui/icons/Toys";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LoadingSpinner from "../utilities/loading-spinner";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import ControlDeviceDialog from "../utilities/dialog/control-device-dialog";
import { ControlDeviceDialogInternal } from "../utilities/dialog";
import AppConfig from "../../config/AppConfig";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BarChartIcon from "@material-ui/icons/BarChart";
import SettingsIcon from "@material-ui/icons/Settings";
import RefreshIcon from "@material-ui/icons/Refresh";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import Grow from "@material-ui/core/Grow";
import BottomAppBar from "../utilities/app-bar";
import { UpdateDeviceAlias, DeviceDataChartDialog } from "../utilities/dialog";
import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import TenantService from "../../services/tenant/tenant.service";

const BList = AppConfig.configs.BeList;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bottomBorder: {
    marginBottom: "38px",
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperTitle: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paddingTop: {
    paddingTop: "9px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
const deviceStatus = {
  All: "2",
  Online: "1",
  Offline: "0",
  Off: "3",
};
const co2Status = {
  All: "-1",
  MoreThan1000: "2",
  From500To1000: "1",
  Under500: "0",
};
const filterRemainingStatus = {
  All: "1",
  LessThan30Days: "0",
};
const defaultFilter = {
  search: "",
  onlineStatus: deviceStatus.All,
  co2Status: co2Status.All,
  filterStatus: filterRemainingStatus.All,
  serverity: -1,
  floor: -1,
};
let previousYPosition = 0;

const DeviceList = () => {
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const [searchParams, setSearchParams] = useState({
    selectedBeValue: BList[0].value,
  });
  const [] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [dialogState, setDialogState] = useState({
    selectedDevice: null,
    openDialog: false,
    openUpdateDeviceDialog: false,
    openDialogForInternalDevice: false,
    openDeviceChartDialog: false,
  });
  const [collapsedStatus, setCollapsedStatus] = useState({
    filterCollapsed: true,
  });
  const [buildings, setBuidings] = useState([]);
  const [showAdvancedFilter, setShowAdvancedFilter] = React.useState(false);
  // promise tracker
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    trackPromise(search());
    // search();
    // // https://stackoverflow.com/questions/53024496/state-not-updating-when-using-react-state-hook-within-setinterval
    const interval = setInterval(() => {
      search();
    }, 60000);
    return () => clearInterval(interval);
  }, [searchParams]);

  // huypq modified 12/2/2021, scroll to old y position after re-render
  useEffect(() => {
    if (previousYPosition > 0) {
      window.scrollTo(0, previousYPosition);
      previousYPosition = 0;
    }
  }, [devices]);

  useEffect(() => {
    getBuildings();
  }, []);

  const getBuildings = async () => {
    try {
      const buildingResponse = await TenantService.getBuildings();
      buildingResponse.push({text: 'All', value: ''});
      setBuidings(buildingResponse);
      setSearchParams({selectedBeValue: buildingResponse[0].value});
    } catch (err) {
      console.log(err);
    }
  };

  const search = async () => {
    var filterInput = getFilter();
    await getPaging(filterInput);
  };

  const getSearchParams = () => {
    if (searchParams == null) {
      return "";
    }
    if (searchParams.selectedBeValue == "") {
      return searchParams.selectedBeValue;
    }
    return `${searchParams.selectedBeValue}-`;
  };

  const getFilter = () => ({
    search: getSearchParams(),
    // search: "",
    start: 0,
    length: 100,
  });

  const getPaging = async (filterInput) => {
    try {
      var response = await DeviceService.getDevices(filterInput);
      setDevices(response);
    } catch (error) {
      alert(error);
    }
  };

  const handleOnlineStatusChange = (e) => {
    handleOnlineStatus(e.target.value);
  };

  const handleOnlineStatus = (val) => {
    const nextFilter = { ...defaultFilter, onlineStatus: val };
    setFilter(nextFilter);
  };

  const filterServerity = (val) => {
    const nextFilter = { ...defaultFilter, serverity: val };
    setFilter(nextFilter);
  };

  const resetFilter = () => {
    setFilter(defaultFilter);
  };

  const handleFilterStatusChange = (e) => {
    setFilter({ ...filter, filterStatus: e.target.value });
  };

  const handleC02StatusChange = (e) => {
    setFilter({ ...filter, co2Status: e.target.value });
  };

  const getFilterStatus = (filter) => {
    if (filter.lifespan == 0) {
      return "error";
    }
    return "success";
  };

  const getFilterRemaining = (filter) => {
    return Math.round(filter.life / 24);
  };

  const getFilterName = (name) => {
    if (name.includes("H13/14")) return "H13/14 filter";
    if (name.includes("TVOC")) return "TOVC filter";
    if (name.includes("F9")) return "F9 filter";
    if (name.includes("G4")) return "G4 filter";
    if (name.includes("Washable")) return "Washable filter";
  };

  const getDeviceSubReport = (device) => {
    return `Temp: ${device.monitor.temp} ℃ | Humidity: ${device.monitor.humidity} %`;
  };

  const onChangeToolbarFilter = (event, name) => {
    let search = event.target.value;
    if (name == "be") {
      const nextSearchParam = { ...searchParams, selectedBeValue: search };
      setSearchParams(nextSearchParam);
    }

    if (name == "floor") {
      const floor = parseInt(search);
      setFilter({ ...filter, floor: floor });
    }
  };

  const handleOpenControlDialog = (device) => {
    // huypq modified 12/2/2021, return to y position after rerender
    previousYPosition = window.pageYOffset;
    if (device.type == 1) {
      setDialogState({
        ...dialogState,
        selectedDevice: device,
        openDialog: true,
      });
    } else {
      setDialogState({
        ...dialogState,
        selectedDevice: device,
        openDialogForInternalDevice: true,
      });
    }
  };

  const handleUpdateAliasDialog = (device) => {
    previousYPosition = window.pageYOffset;
    setDialogState({
      ...dialogState,
      selectedDevice: device,
      openUpdateDeviceDialog: true,
    });
  };

  const handleCloseControlDialog = (updatedDevice) => {
    if (updatedDevice) {
      var currentDevice = devices.filter((dv) => dv.id == updatedDevice.id)[0];
      const index = devices.indexOf(currentDevice);
      if (index != -1) {
        devices[index] = updatedDevice;
      }
      var test = [...devices];
      setDevices(test);
    }

    setDialogState({
      ...dialogState,
      selectedDevice: null,
      openDialog: false,
      openUpdateDeviceDialog: false,
      openDialogForInternalDevice: false,
      openDeviceChartDialog: false,
    });
  };

  const getCardClass = (device) => {
    if (device.monitor.mode == 17 || device.monitor.mode == 18) {
      return " disable-item";
    }

    return "";
  };

  const handleChangeFilter = (s) => {
    setFilter({ ...filter, search: s });
    // setDevices([...devices]);
  };

  const filterDevice = (devices) => {
    let result = devices;

    if (filter == null || filter == undefined) {
      return result;
    }

    if (filter.search != "") {
      result = result.filter((d) =>
        d.managedDeviceAlias.toLowerCase().includes(filter.search.toLowerCase())
      );
    }

    if (filter.onlineStatus != deviceStatus.All) {
      if (filter.onlineStatus == deviceStatus.Offline) {
        result = result.filter(
          (d) =>
            d.monitor.isOnline === (filter.onlineStatus === deviceStatus.Online)
        );
      } else if (filter.onlineStatus == deviceStatus.Off) {
        result = result.filter(
          (d) => d.monitor.mode == 17 || d.monitor.mode == 18
        );
      }
    }

    if (filter.co2Status != co2Status.All) {
      result = result.filter((d) => filterCo2(d, filter.co2Status));
    }

    if (filter.filterStatus != filterRemainingStatus.All) {
      result = result.filter((d) =>
        d.monitor.filters.some((f) => f.life < 720)
      );
    }

    if (filter.serverity >= 0 && filter.serverity < 6) {
      result = result.filter(
        (d) => DeviceService.getDeviceServerity(d) == filter.serverity
      );
    }

    if (filter.floor > 0) {
      result = result.filter((d) => d.floor == filter.floor);
    }

    return result;
  };

  const openChart = (d) => {
    setDialogState({
      ...dialogState,
      selectedDevice: d,
      openDeviceChartDialog: true,
    });
  };

  const filterCo2 = (d, co2St) => {
    if (co2St == co2Status.MoreThan1000) {
      return d.monitor.co2 >= 1000;
    }

    if (co2St == co2Status.From500To1000) {
      return d.monitor.co2 < 1000 && d.monitor.co2 >= 500;
    }

    if (co2St == co2Status.Under500) {
      return d.monitor.co2 < 500;
    }
  };

  const executeDeviceAlias = async (type) => {
    const alias = getSearchParams();

    try {
      await trackPromise(
        DeviceService.executeDeviceAlias({ alias, type: type })
      );
      trackPromise(search());
    } catch (error) {
      alert(error);
    }
  };

  const handleCollapsedDeviceFilters = () => {
    const isFilterCollapsed = !collapsedStatus.filterCollapsed;
    setCollapsedStatus({
      ...collapsedStatus,
      filterCollapsed: isFilterCollapsed,
    });
  };

  return (
    <React.Fragment>
      <div
        className={`${classes.root} ${classes.bottomBorder}`}
        id="device-list-wrapper"
      >
        <div className="filter-box">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <RadioGroup
                aria-label="Select be"
                name="be"
                value={searchParams.selectedBeValue}
                onChange={(e) => onChangeToolbarFilter(e, "be")}
                row
              >
                {buildings.map((be) => (
                  <FormControlLabel
                    // className={classes.paddingTop}
                    value={be.value}
                    control={<Radio />}
                    label={be.text}
                    key={be.value}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={3}>
              <RadioGroup
                aria-label="Select floor"
                name="floor"
                value={filter.floor}
                onChange={(e) => onChangeToolbarFilter(e, "floor")}
                row
              >
                {AppConfig.configs.floors.map((f) => (
                  <FormControlLabel
                    value={f.value}
                    control={<Radio />}
                    label={f.text}
                    key={f.value}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={6} sm={1}>
              <span style={{ marginBottom: "5px" }}>
                <TextField
                  id="filter-alias"
                  label="Filter"
                  type="text"
                  InputProps={{
                    className: "white-text",
                  }}
                  onChange={(e) => handleChangeFilter(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </span>
            </Grid>
            {/* <Grid item xs={6} sm={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showAdvancedFilter}
                    onChange={() => {
                      setShowAdvancedFilter(!showAdvancedFilter);
                    }}
                  />
                }
                label="Advance"
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={2}>
              <span>
                <span className="chip-label">
                  <WifiIcon isOnline={true} className="padding9-icon" />
                  {": " +
                    devices?.filter((d) => d.monitor.isOnline).length +
                    ""}
                </span>
                <span className="chip-label">
                  <WifiIcon isOnline={false} className="padding9-icon" />
                  {": " +
                    devices?.filter((d) => !d.monitor.isOnline).length +
                    ""}
                </span>
                <span className="chip-label">
                  <PowerStatus className="padding9-icon" isOn={false} />
                  {": " +
                    devices?.filter(
                      (d) => d.monitor.mode == 17 || d.monitor.mode == 18
                    ).length}
                </span>
              </span>
            </Grid> */}
          </Grid>
        </div>

        <div style={{ float: "right", marginBottom: "2px", padding: "5px" }}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => executeDeviceAlias(1)}
          >
            Start all
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            style={{ marginLeft: "5px" }}
            onClick={() => executeDeviceAlias(0)}
          >
            Stop all
          </Button>
        </div>
        <div
          className="control-box"
          style={{ display: showAdvancedFilter ? "block" : "none" }}
        ></div>
        {promiseInProgress === true ? (
          <LoadingSpinner />
        ) : (
          <Grid container spacing={1}>
            {filterDevice(devices).map((d) => (
              <Grid className="device-item" item xs={12} sm={2} key={d.id}>
                <Card className={classes.root + getCardClass(d)}>
                  <CardHeader
                    title={d.managedDeviceAlias}
                    subheader={getDeviceSubReport(d)}
                    style={{ paddingTop: "5px" }}
                  />
                  <CardContent>
                    {d.type == 1 ? (
                      <div className={classes.paperTitle}>
                        <div>
                          <span className="info-label">AQI: </span>
                          <span
                            className={`device-status ${d.monitor.serverity}`}
                          >
                            {d.monitor.isOnline ? d.monitor.aqi : 0}
                          </span>
                          <span className="info-label"> | CO2: </span>
                          <span
                            className={`device-status ${d.monitor.serverity}`}
                          >
                            {d.monitor.isOnline ? d.monitor.co2 : 0}
                          </span>
                          <div className={d.monitor.serverity}>
                            <i>{d.monitor.status}</i>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.paperTitle}>
                        <div>
                          <span className="info-label">AQI: </span>
                          <span
                            className={`device-status ${d.monitor.serverity}`}
                          >
                            {d.monitor.isOnline ? d.monitor.aqi : 0}
                          </span>
                          <div className={d.monitor.serverity}>
                            <i>{d.monitor.status}</i>
                          </div>
                        </div>
                      </div>
                    )}

                    <div>
                      <span
                        className={
                          d.monitor.rank > 0
                            ? "round-border"
                            : "round-border darkmod-disabled"
                        }
                        onClick={() => handleOpenControlDialog(d)}
                      >
                        {d.monitor.isInAutoMode ? (
                          <BrightnessAutoIcon fontSize="small" />
                        ) : (
                          ""
                        )}
                        {d.heat ? <WbSunnyIcon fontSize="small" /> : ""}
                        <ToysIcon
                          className="on"
                          // size="large"
                          className={
                            d.monitor.isOnline &&
                            d.monitor.rank > 0 &&
                            d.monitor.mode != 17 &&
                            d.monitor.mode != 18
                              ? "online"
                              : "darkmod-disabled"
                          }
                          style={{ fontSize: 18 }}
                        />
                        <span
                          className={
                            d.monitor.isOnline &&
                            d.monitor.rank > 0 &&
                            d.monitor.mode != 17 &&
                            d.monitor.mode != 18
                              ? "online"
                              : "darkmod-disabled"
                          }
                        >
                          {DeviceService.getDeviceRankAirDisplayLabel(d)}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span
                        className="round-border-small"
                        onClick={() => {
                          handleUpdateAliasDialog(d);
                        }}
                      >
                        <SettingsIcon fontSize="small" />
                      </span>
                      <span
                        className="round-border-small"
                        onClick={() => {
                          openChart(d);
                        }}
                      >
                        <BarChartIcon fontSize="small" />
                      </span>
                      <span className="round-border-small">
                        <RefreshIcon fontSize="small" />
                      </span>
                      <span
                        className="round-border-small"
                        onClick={handleCollapsedDeviceFilters}
                      >
                        <DynamicFeedIcon fontSize="small" />
                      </span>
                    </div>
                    {collapsedStatus.filterCollapsed ? (
                      ""
                    ) : (
                      <div className="device-filters">
                        <Grow
                          in={!collapsedStatus.filterCollapsed}
                          style={{ transformOrigin: "0 0 0" }}
                          {...(!collapsedStatus.filterCollapsed
                            ? { timeout: 689 }
                            : {})}
                        >
                          <div style={{ marginTop: "5px" }}>
                            <div>
                              <i>
                                {"ProductNo: " +
                                  DeviceService.getDisplayProductNo(
                                    d?.managedDeviceProductNo
                                  ) +
                                  ""}
                              </i>
                            </div>
                            {d.monitor.filters.map((filter) => (
                              <div key={filter.name}>
                                <div className="lblLeftLabel">
                                  <div className="fix-length-label float-left"></div>
                                  <i className="float-left">
                                    <i>{getFilterName(filter.name)}</i>
                                    {" : " +
                                      getFilterRemaining(filter) +
                                      " days left"}
                                  </i>
                                </div>
                                <Progress
                                  percent={
                                    (filter.life * 100) / filter.lifespan
                                  }
                                  status={getFilterStatus(filter)}
                                />
                              </div>
                            ))}
                          </div>
                        </Grow>
                      </div>
                    )}
                  </CardContent>
                  <CardActions disableSpacing></CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        <ControlDeviceDialog
          selectedDevice={dialogState.selectedDevice}
          open={dialogState.openDialog}
          onClose={handleCloseControlDialog}
        />
        <ControlDeviceDialogInternal
          selectedDevice={dialogState.selectedDevice}
          open={dialogState.openDialogForInternalDevice}
          onClose={handleCloseControlDialog}
        />
        <UpdateDeviceAlias
          device={dialogState.selectedDevice}
          open={dialogState.openUpdateDeviceDialog}
          onClose={handleCloseControlDialog}
        />
        <DeviceDataChartDialog
          device={dialogState.selectedDevice}
          open={dialogState.openDeviceChartDialog}
          onClose={handleCloseControlDialog}
        />
      </div>
      <BottomAppBar
        devices={devices}
        handleOnlineStatus={handleOnlineStatus}
        filterServerity={filterServerity}
        resetFilter={resetFilter}
      />
    </React.Fragment>
  );
};

export default DeviceList;
