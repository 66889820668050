import axios from "axios";
import AppConfig from "../../config";
import jwt_decode from "jwt-decode";
import axiosClient from "../axios-client";
import RequestService from "../request.service";
import MenuService from "../menu";

export class AuthService {
  async authenticate(username, password) {
    try {
      const response = await axios.post(AppConfig.auth.tokenUrl, {
        username: username,
        password: password,
        remember: false,
      });

      if (response.status != 200) {
        return false;
      } else {
        const token = response.data.accessToken;
        localStorage.setItem(AppConfig.auth.tokenKey, token);
        localStorage.setItem(AppConfig.auth.username, response.data.userName);

        // huypq modified 4/4/2021 , request additional information when login
        await this.requestUserInfo();
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  async requestUserInfo() {
    const menu = await MenuService.getUserMenu();
    localStorage.setItem("menu", JSON.stringify(menu));
  }

  async changePassword(changePasswordInput) {
    const response = await axiosClient.put(
      AppConfig.auth.changePasswordUrl,
      changePasswordInput
    );
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    return rs;
  }

  signout() {
    localStorage.removeItem(AppConfig.auth.tokenKey);
    localStorage.removeItem("menu");
  }

  isAuthenticate() {
    return localStorage.getItem(AppConfig.auth.tokenKey) != null;
  }

  getToken() {
    return localStorage.getItem(AppConfig.auth.tokenKey);
  }

  getRole() {
    const token = localStorage.getItem(AppConfig.auth.tokenKey);
    if (token == null) {
      return null;
    }
    const decodedJwt = jwt_decode(token);
    const role = decodedJwt[AppConfig.configs.jwtPayload.role];
    return role;
  }

  getPermission(formName) {
    let permission = {
      canEdit: false,
      canRead: false,
      canAdd: false,
      canDelete: false,
    };
    const role = this.getRole();

    if (role == "admin" || role == "Admin" || role == "tenant") {
      permission.canAdd = true;
      permission.canDelete = true;
      permission.canEdit = true;
    }
    return permission;
  }

  getMenuCache() {
    return JSON.parse(localStorage.getItem("menu"));
  }

  /// huypq modifed add menu permission
  hasMenuPermission(url) {
    try {
      const menu = this.getMenuCache();
      if (menu == null) return false;
      const hasMenuPermission = menu.some(m => m.url.toLowerCase() === url.toLowerCase());
      if (!hasMenuPermission) {
        alert("User do not have permission to access this feature!!!");
      }
      return hasMenuPermission;
    } catch (error) {
      return false;
    }
  }
}

export default new AuthService();
