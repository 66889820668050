import React, { useState, useEffect, useLayoutEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core";
import DeviceHistoryService from "../../../services/device-history";
import Button from "@material-ui/core/Button";
import AreaChartWrapper from "../charts";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const aqiCols = [
  {
    type: "datetime",
    label: "year",
  },
  {
    label: "Aqi",
    type: "number",
  },
];
const co2Cols = [
  {
    type: "datetime",
    label: "year",
  },
  {
    label: "Co2",
    type: "number",
  },
];

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
}));
export default function DeviceDataChartDialog({ device, open, onClose }) {
  const classes = useStyles();
  const [rows, setRows] = useState({
    aqiRows: [],
    co2Rows: [],
  });

  useEffect(() => {
    if (!open) {
      return;
    }
    fetch();
  }, [device]);

  const fetch = async () => {
    if (device == null || device.id == null) {
      return;
    }
    const devicesHistoryAggregate = await DeviceHistoryService.getDeviceHourlyHistory(
      device.id,
      120
    );
    const aggregateAqiRows = devicesHistoryAggregate.map((d) => getAqiRows(d));
    const aggregateCo2Rows = devicesHistoryAggregate.map((d) => getCo2Rows(d));
    setRows({
      aqiRows: aggregateAqiRows,
      co2Rows: aggregateCo2Rows,
    });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAqiRows = (deviceAggregate) => {
    const dataDate = new Date(deviceAggregate.dataDate);
    const year = dataDate.getFullYear();
    const month = dataDate.getMonth();
    const day = dataDate.getDate();
    const hour = dataDate.getHours();
    const minutes = dataDate.getMinutes();
    return [`Date(${year},${month},${day},${hour})`, deviceAggregate.avgAqi];
  };

  const getCo2Rows = (deviceAggregate) => {
    const dataDate = new Date(deviceAggregate.dataDate);
    const year = dataDate.getFullYear();
    const month = dataDate.getMonth();
    const day = dataDate.getDate();
    const hour = dataDate.getHours();
    const minutes = dataDate.getMinutes();
    return [`Date(${year},${month},${day},${hour})`, deviceAggregate.avgCo2];
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {/* Control device : {device?.managedDeviceAlias} */}
          Data chart
        </DialogTitle>
        <DialogContent id="donutchart">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Aqi" {...a11yProps(0)} />
            {device?.type == 1 && <Tab label="Co2" {...a11yProps(1)} />}
          </Tabs>
          <TabPanel value={value} index={0}>
            <AreaChartWrapper
              rows={rows.aqiRows}
              columns={aqiCols}
              yAxisLabel="Aqi"
              xAxisLabel="Datetime"
              title="Aqi logs"
              color="#0B3E98"
            />
          </TabPanel>
          {device?.type == 1 && (
            <TabPanel value={value} index={1}>
              <AreaChartWrapper
                rows={rows.co2Rows}
                columns={co2Cols}
                yAxisLabel="Co2"
                xAxisLabel="Datetime"
                title="Co2 logs"
                color="#BDBD19"
              />
            </TabPanel>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(null)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
