import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CalendarService from "../../services/calendar";
import "./calendar-detail.css";
import calendarService from "../../services/calendar/calendar.service";
import DeviceService from "../../services/device";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import AppConfig from "../../config";
import Box from "@material-ui/core/Box";
import TenantService from '../../services/tenant';

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const BList = AppConfig.configs.BeList;
const minutes = AppConfig.configs.minutes;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 890,
    // padding: 9,
    // backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  foot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

let calendarJob = null;
export default function CalendarDetail(props) {
  const calendarJob = props?.location?.state?.calendarJob;
  if (calendarJob && calendarJob.func) {
    calendarJob.air = DeviceService.getAirCommandByFunc(calendarJob.func);
    calendarJob.heat = DeviceService.getHeatByFunc(calendarJob.func);
  }
  const [state, setState] = useState({
    calendar: props?.location?.state?.calendarJob ?? {
      id: "",
      daysOfWeek: [],
      hour: 0,
      minute: 0,
      jobType: 1,
      rank: 1,
      air: "080",
      heat: false,
      func: "",
      isEnabled: false,
      deviceAlias: [],
    },
    errors: {
      daysOfWeekErr: "",
      hourErr: "",
      minuteErr: "",
      isEnabledErr: "",
    },
  });
  const [devicesAlias, setDevicesAlias] = useState([]);
  const [selectedBe, setSelectedBe] = useState(BList[BList.length - 1].value);
  const [checkAllAlias, setCheckAllAlias] = useState(false);
  const [buildings, setBuidings] = useState(BList);
  const classes = useStyles();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    resetCheckAllSelection();
  }, [state.calendar.deviceAlias.length, selectedBe]);

  const getDevicesAlias = async () => {
    try {
      var response = await DeviceService.getDeviceAlias();
      setDevicesAlias(response);
      setSelectedBe(BList[0].value);
    } catch (error) {
      alert(error);
    }
  };

  const fetch = async () => {
    await getDevicesAlias();
    await getBuildings();
  }

  const getBuildings = async () => {
    try {
      const buildingResponse = await TenantService.getBuildings();
      buildingResponse.push({text: 'All', value: ''});
      setBuidings(buildingResponse);
      setSelectedBe(buildingResponse[0].value);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e, type) => {
    switch (type) {
      case "daysOfWeek":
        handleDayOfWeek(e);
        return;
      case "hour":
        handleHour(e);
        return;
      case "minute":
        handleMinute(e);
        return;
      case "isEnabled":
        handledIsEnabled(e);
        return;
      case "jobType":
        handleProp(parseInt(e.target.value), "jobType");
        return;
      case "rank":
        handleProp(parseInt(e.target.value), "rank");
        return;
      case "air":
        handleProp(e.target.value, "air");
        return;
      case "heat":
        handleProp(e.target.checked, "heat");
        return;
      default:
        return;
    }
  };

  const isCheckedDay = (index) => {
    const dayOfWeekState = state.calendar.daysOfWeek;
    if (!dayOfWeekState) {
      return false;
    } else {
      const item = dayOfWeekState.find((x) => x == index);
      return item != null;
    }
  };

  const handleDayOfWeek = (e) => {
    var dayName = e.target.name;
    var dow = daysOfWeek.find((d) => d === dayName);
    var index = daysOfWeek.indexOf(dow);
    const isChecked = e.target.checked;

    // set lai dayOfWeek in state
    let dayOfWeekState = state.calendar.daysOfWeek
      ? state.calendar.daysOfWeek
      : [];
    if (isChecked) {
      dayOfWeekState.push(index);
    } else {
      //remove
      dayOfWeekState = dayOfWeekState.filter((x) => x != index);
    }

    const nextState = {
      ...state,
      calendar: {
        ...state.calendar,
        daysOfWeek: dayOfWeekState,
      },
    };
    if (!dayOfWeekState || dayOfWeekState.length === 0) {
      nextState.errors.daysOfWeekErr = "Days of week can not be empty";
    } else {
      nextState.errors.daysOfWeekErr = "";
    }

    console.log("Next state: ", nextState);
    setState(nextState);
  };

  const handleHour = (e) => {
    const hour = parseInt(e.target.value);
    console.log(hour);
    const nextState = {
      ...state,
      calendar: {
        ...state.calendar,
        hour: hour,
      },
    };
    if (hour < 0 || hour > 23) {
      nextState.errors.hourErr = "Hour is not valid";
    } else {
      nextState.errors.hourErr = "";
    }
    console.log("next state: ", nextState);
    setState(nextState);
  };

  const handleProp = (val, prop) => {
    const nextState = {
      ...state,
    };
    nextState.calendar[prop] = val;
    console.log("next state: ", nextState);
    setState(nextState);
  };

  const handledIsEnabled = (e) => {
    const isEnabled = e.target.checked;
    const nextState = {
      ...state,
      calendar: {
        ...state.calendar,
        isEnabled: isEnabled,
      },
    };
    console.log("next state: ", nextState);
    setState(nextState);
  };

  const handleMinute = (e) => {
    const min = parseInt(e.target.value);
    const nextState = {
      ...state,
      calendar: {
        ...state.calendar,
        minute: min,
      },
    };
    if (min < 0 || min > 59) {
      nextState.errors.minuteErr = "Minute is not valid";
    } else {
      nextState.errors.minuteErr = "";
    }
    console.log("next state: ", nextState);
    setState(nextState);
  };

  const validateData = () => {
    if (
      state.calendar.daysOfWeek == null ||
      state.calendar.daysOfWeek.length == 0
    ) {
      state.errors.daysOfWeekErr = "Days of week can not be empty";
    }

    if (state.calendar.hour < 0 || state.calendar.hour > 23) {
      state.errors.hourErr = "Hour is not valid";
    }

    if (state.calendar.minute < 0 || state.calendar.minute > 59) {
      state.errors.minuteErr = "Minute is not valid";
    }

    const nextState = {
      ...state,
      errors: {
        daysOfWeekErr: state.errors.daysOfWeekErr,
        hourErr: state.errors.hourErr,
        minuteErr: state.errors.minuteErr,
        isEnabledErr: "",
      },
    };
    setState(nextState);

    return (
      state.errors.daysOfWeekErr.length === 0 &&
      state.errors.hourErr.length === 0 &&
      state.errors.minuteErr.length === 0 &&
      state.errors.isEnabledErr.length === 0
    );
  };

  const submit = async () => {
    if (validateData() === false) {
      return;
    }
    const calendar = state.calendar;
    calendar.func = DeviceService.buildFuncFromAirHeatCode(
      calendar.air,
      calendar.heat ? "40000" : "00000"
    );
    console.log("func: ", calendar.func);
    try {
      await calendarService.upsert(calendar);
      alert("Success!");
    } catch (error) {
      alert(error);
    }
  };

  const goBack = () => {
    props.history.push("/calendar");
  };

  const onChangeBe = (e) => {
    setSelectedBe(e.target.value);
    // resetCheckAllSelection();
  };

  // change status of the select all choice base on if all current alias of Be is selected
  const resetCheckAllSelection = () => {
    const currentDisplayingAlias = devicesAlias.filter(
      (d) => getDisplay(d) != "none"
    );
    const isCheckAllAlias = !currentDisplayingAlias.some(
      (d) => !isCheckedAlias(d)
    );
    console.log("isCheckAllAlias: ", isCheckAllAlias);
    setCheckAllAlias(isCheckAllAlias);
  };

  const getDisplay = (alias) => {
    if (selectedBe == null) return "none";
    if (alias == null) {
      return;
    }
    if (alias.startsWith(selectedBe)) return "inline";
    return "none";
  };

  const isCheckedAlias = (a) => {
    if (state.calendar.deviceAlias == null) return false;
    return state.calendar.deviceAlias.includes(a);
  };

  const handleChangeAlias = (e) => {
    let aliases = state.calendar.deviceAlias ?? [];
    if (e.target.checked) {
      aliases.push(e.target.name);
    } else {
      aliases = aliases.filter((x) => x != e.target.name);
    }
    setState({
      ...state,
      calendar: {
        ...state.calendar,
        deviceAlias: aliases,
      },
    });
  };

  const handleAllChangeAlias = (e) => {
    setCheckAllAlias(e.target.checked);

    let aliases = state.calendar.deviceAlias ?? [];
    if (e.target.checked) {
      const bAlias = devicesAlias.filter((x) => x.startsWith(selectedBe));
      for (let i = 0; i < bAlias.length; i++) {
        const element = bAlias[i];
        if (!aliases.includes(element)) {
          aliases.push(element);
        }
      }
    } else {
      aliases = aliases.filter((x) => !x.startsWith(selectedBe));
    }

    setState({
      ...state,
      calendar: {
        ...state.calendar,
        deviceAlias: aliases,
      },
    });
  };

  return (
    <React.Fragment>
      <div className="jss130">
        <Button color="primary">
          <AddAlarmIcon />
        </Button>
        <div className={classes.root}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12}>
              <FormControl
                required
                error={
                  state.errors.daysOfWeekErr != null &&
                  state.errors.daysOfWeekErr.length > 0
                }
                component="fieldset"
                className={classes.formControl}
              >
                <FormLabel component="legend">Select days of week</FormLabel>
                <FormGroup row>
                  {daysOfWeek.map((d, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckedDay(index)}
                          onChange={(e) => handleChange(e, "daysOfWeek")}
                          name={d}
                          color="primary"
                        />
                      }
                      label={d}
                      key={index}
                    />
                  ))}
                </FormGroup>
                <FormHelperText>{state.errors.daysOfWeekErr}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                aria-label="Select be"
                name="be"
                value={selectedBe}
                onChange={onChangeBe}
                row
              >
                {buildings.map((be) => (
                  <FormControlLabel
                    value={be.value}
                    control={<Radio />}
                    label={be.text}
                    key={be.text}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12} className="overflow-box">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkAllAlias}
                    onChange={handleAllChangeAlias}
                    name="all"
                    color="primary"
                  />
                }
                label="Select all"
                style={{ marginBottom: "5px" }}
              />
              {devicesAlias.map((a, index) => (
                <Box display={getDisplay(a)} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckedAlias(a)}
                        onChange={handleChangeAlias}
                        name={a}
                        color="primary"
                      />
                    }
                    label={a}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-simple-select-label">Hour</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="hour-select"
                  value={state.calendar.hour}
                  onChange={(e) => handleChange(e, "hour")}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={11}>11</MenuItem>
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value={13}>13</MenuItem>
                  <MenuItem value={14}>14</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={16}>16</MenuItem>
                  <MenuItem value={17}>17</MenuItem>
                  <MenuItem value={18}>18</MenuItem>
                  <MenuItem value={19}>19</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={21}>21</MenuItem>
                  <MenuItem value={22}>22</MenuItem>
                  <MenuItem value={23}>23</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-simple-select-label">Minute</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="select-minute"
                  value={state.calendar.minute}
                  onChange={(e) => handleChange(e, "minute")}
                >
                  {minutes.map((m, index) => (
                    <MenuItem value={m} key={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        state.calendar.isEnabled == true
                          ? state.calendar.isEnabled
                          : false
                      }
                      onChange={(e) => handleChange(e, "isEnabled")}
                      name="isEnabled"
                    />
                  }
                  label="Enabled"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Choose control command
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state.calendar.jobType}
                  onChange={(e) => handleChange(e, "jobType")}
                >
                  <MenuItem value={1}>Turn on</MenuItem>
                  <MenuItem value={0}>Turn off</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{display: state.calendar.jobType != 0 ? 'block' : 'none'}}>
              <FormControl className={classes.formControl} component="fieldset">
                <FormLabel component="legend">Fan speed</FormLabel>
                <RadioGroup
                  aria-label="Select fan speed"
                  name="fanspeed"
                  value={state.calendar?.rank}
                  onChange={(e) => handleChange(e, "rank")}
                  row
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Rank 1"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Rank 2"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Rank 3"
                  />
                  <FormControlLabel
                    value={4}
                    control={<Radio />}
                    label="Rank 4"
                  />
                  <FormControlLabel
                    value={5}
                    control={<Radio />}
                    label="Rank 5"
                  />
                  <FormControlLabel
                    value={6}
                    control={<Radio />}
                    label="Rank 6"
                  />
                  
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{display: state.calendar.jobType != 0 ? 'block' : 'none'}}>
              <FormControl className={classes.formControl} component="fieldset">
                <FormLabel component="legend">Air mode</FormLabel>
                <RadioGroup
                  aria-label="Select air mode"
                  name="air mode"
                  value={state.calendar?.air}
                  onChange={(e) => handleChange(e, "air")}
                  row
                >
                  <FormControlLabel
                    value="010"
                    control={<Radio />}
                    label="Internal"
                  />
                  <FormControlLabel
                    value="080"
                    control={<Radio />}
                    label="25%"
                  />
                  <FormControlLabel
                    value="100"
                    control={<Radio />}
                    label="50%"
                  />
                  <FormControlLabel
                    value="180"
                    control={<Radio />}
                    label="75%"
                  />
                  <FormControlLabel
                    value="200"
                    control={<Radio />}
                    label="External"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{display: state.calendar.jobType != 0 ? 'block' : 'none'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.calendar.heat}
                    name="checkedA"
                    onChange={(e) => handleChange(e, "heat")}
                  />
                }
                label="Sưởi"
              />
            </Grid>
          </Grid>
          <Divider />
          <div className={classes.foot}>
            <Button variant="contained" color="primary" onClick={submit}>
              {" "}
              Save{" "}
            </Button>
            <Button variant="contained" onClick={goBack}>
              {" "}
              Go back{" "}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

