import React from "react";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import AdbIcon from "@material-ui/icons/Adb";
import ScheduleIcon from "@material-ui/icons/Schedule";

const IconEnum = {
  device: 1,
  schedule: 2,
  user: 3,
  automatic: 4,
  tenant: 5,
};

const GetIcon = ({iconType}) => {
  switch (iconType) {
    case IconEnum.device:
      return <DashboardOutlinedIcon fontSize="large" />;
    case IconEnum.schedule:
      return <ScheduleIcon fontSize="large" />;
    case IconEnum.user:
      return <PeopleOutlineOutlinedIcon fontSize="large" />;
    case IconEnum.automatic:
      return <AdbIcon fontSize="large" />;
    case IconEnum.tenant:
      return <SupervisedUserCircleIcon fontSize="large" />;
    default:
      return <ScheduleIcon fontSize="large" />;
  }
};

export default GetIcon;
