import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import "./tenant.css";
import DeviceAliasAutoComplete from "../../utilities/device-alias-autocomplete";
import BuildingSelector from "../../utilities/building-select-autocomplete";
import TenantService from "../../../services/tenant";
import {
  BrowserRouter as Router,
  Link
} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "89%",
  },
  formControl: {
    margin: theme.spacing(2),
  },
  foot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function TenantDetail(prop) {
  const classes = useStyles();
  const [tenantState, setTenantState] = useState({
    id: "",
    name: "",
    code: "",
    description: "",
    aliases: [],
    errors: {
      nameError: "",
      codeError: "",
    },
    buildings: []
  });
  const [] = useState([]);

  useEffect(() => {
    if (prop?.location?.state?.tenant != null) {
      setTenantState({
        ...prop?.location.state.tenant,
        errors: {
          nameError: "",
          codeError: "",
        },
      });
    }
  }, [prop]);

  const handleChangeName = (tName) => {
    let nextState = {
      ...tenantState,
      name: tName,
    };

    if (tName == null || tName.length == 0) {
      nextState.errors.nameError = "Tenant name can not be empty!";
    } else {
      nextState.errors.nameError = "";
    }

    setTenantState(nextState);
  };

  const handleChangeCode = (tCode) => {
    let nextState = {
      ...tenantState,
      code: tCode,
    };

    if (tCode == null || tCode.length == 0) {
      nextState.errors.codeError = "Tenant code can not be empty!";
    } else {
      nextState.errors.codeError = "";
    }

    setTenantState(nextState);
  };

  const handleChangeAlias = (values) => {
    setTenantState({
      ...tenantState,
      aliases: values,
    });
  };

  const handleChangeBuildings = (values) => {
    setTenantState({
      ...tenantState,
      buildings: values
    })
  }

  const handleChangeDescription = (value) => {
    setTenantState({
      ...tenantState,
      description: value,
    });
  };

  const submit = async () => {
    const tenant = {
      ...tenantState,
      errors: null,
    };
    try {
      if (tenant.id != null && tenant.id.length > 0) {
        await TenantService.update(tenant);
      } else {
        await TenantService.add(tenant);
      }
      alert("Tenant changed successfully!");
    } catch (err) {
      alert(err);
    }
  };

  const goBack = () => {};

  return (
    <React.Fragment>
      <div className="jss130">
        <div className={classes.root}>
          <Grid container spacing={2} alignItems="center">
            <h3>Domain</h3>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Tenant name"
                error={tenantState.name == null || tenantState.name.length == 0}
                helperText={tenantState.errors.nameError}
                value={tenantState.name}
                // disabled={tenantState.id != null && tenantState.id != ""}
                type="text"
                fullWidth
                onChange={(e) => handleChangeName(e.target.value)}
                // variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="code"
                label="Tenant code"
                error={tenantState.code == null || tenantState.code.length == 0}
                helperText={tenantState.errors.codeError}
                value={tenantState.code}
                // disabled={tenantState.id != null && tenantState.id != ""}
                type="text"
                fullWidth
                onChange={(e) => handleChangeCode(e.target.value)}
                // variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                label="Description"
                type="text"
                fullWidth
                onChange={(e) => handleChangeDescription(e.target.value)}
                // variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <DeviceAliasAutoComplete
                value={tenantState.aliases ? tenantState.aliases : []}
                onChange={handleChangeAlias}
              />
            </Grid>
            <Grid item xs={12}>
              <BuildingSelector values={tenantState.buildings ?? []} onChange={handleChangeBuildings}/>
            </Grid>
          </Grid>
          <Divider />
          <div className={classes.foot}>
            <Button variant="contained" color="primary" onClick={submit}>
              {" "}
              Save{" "}
            </Button>
            <Link
              to={{
                pathname: "/tenant-list"
              }}
            >
              <Button variant="contained" onClick={goBack}>
                {" "}
                Go back{" "}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
