import axios from "axios";
import AppConfig from "../../config";

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(AppConfig.auth.tokenKey);

  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }

  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    
    if (error.response.status === 401) {
      // history.push('/login');
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

class AxiosClient {
  async post(url, obj) {
    return await axios.post(url, obj);
  }

  async put(url, obj) {
    return await axios.put(url, obj);
  }
  
  async get(url) {
    return await axios.get(url);
  }

  async delete(url) {
    return await axios.delete(url);
  }

  async patch(url, data) {
    return await axios.patch(url, data);
  }
}

export default new AxiosClient();
