import RequestService from '../request.service';
import AppConfig from "../../config";

class TenantService {
  constructor() {
      this.buildings = [];
  }

  async getAll() {
      return await RequestService.get(AppConfig.tenant.getAllUrl);
  }

  async add(tenant) {
      return await RequestService.post(AppConfig.tenant.addUrl, tenant);
  }

  async update(tenant) {
      return await RequestService.put(AppConfig.tenant.updateUrl, tenant);
  }

  async delete(id) {
      return await RequestService.delete(AppConfig.tenant.updateUrl, {});
  }

  async getBuildings() {
      if (this.buildings.length > 0) {
          return this.buildings;
      }

      const response = await RequestService.get(AppConfig.tenant.getBuildingsUrl);
      return response.map(x => ({ text: x, value: x}));
  }
}

export default new TenantService();
