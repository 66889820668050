/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function BuildingSelector({values, onChange}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        value={values}
        onChange={(event, value) => onChange(value)}
        id="tags-filled"
        options={buildings.map((option) => option)}
        defaultValue={[buildings[0]]}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Buildings" placeholder="Select buildings" />
        )}
      />
    </div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const buildings = [
  "B1", 'B2', 'B3', 'B4', 'B5', 'B6' , 'B7', 'B8', 'B9'
];
