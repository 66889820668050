import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import UserService from '../../../services/user';
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TenantService from '../../../services/tenant';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function UserList(props) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    const rs = await UserService.getAll();
    console.log(rs);
    setUsers(rs);
  }

  const viewDetail = (u) => {
    props.history.push({
      pathname: '/user-detail',
      search: '?id=' + u.id,
      state: { user: u }
    });
  }


  const deleteUser = async (username) => {
    if (!window.confirm(`Are you sure to delete user ${username}?`)) {
      return;
    }
    try {
      var response = await UserService.delete(username);
      search();
      alert('Change request successful!');
    } catch (error) {
      alert(error);
    }
  }


  const createNew = () => {
    props.history.push('/user-detail');
  }

  return (
    <React.Fragment>
      <h3>Users</h3>
      <div>
        <Button color="primary" onClick={createNew}>
          <PersonAddIcon/>
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Role</TableCell>
              <TableCell align="right">Created at</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((u, index) => (
              <TableRow className="active-row" key={index}>
                <TableCell component="th" scope="row">
                  {u.userName}
                </TableCell>
                <TableCell align="right">{u.email}</TableCell>
                <TableCell align="right">{u?.roles[0]} </TableCell>
                <TableCell align="right">{u.createdAt}</TableCell>

                <TableCell align="center">
                  <Button color="primary" onClick={() => viewDetail(u)}>
                    <EditIcon />
                  </Button>
                  <Button color="secondary" onClick={() => deleteUser(u.userName)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}